import {buildSelector, constToSelectOptions} from "../utils/Utils";
import {API} from "aws-amplify";
import {components} from "react-select";
import React from "react";
import {BlockExerciseModel} from "./Block";



const Option = ({children, ...props}) => {
    let option = props.data
    return (
        <components.Option {...props}>
            <div className="clearfix">
                <div className="mb-3 pull-left">
                    <img alt={"Preview"} className="pull-left mr-2 img-rounded"
                         width="128"
                         height="128"
                         src={option.preview}/>
                </div>
                <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
                    <p style={{"margin": "0"}}><strong>{`[${option.id}] ${option.name}`}</strong>
                    </p>
                    <span style={{"margin": "0"}}>
                        Exercise Level: <strong>{option.fitness_level}</strong>
                    </span><br/>
                </div>
            </div>
        </components.Option>
    );
};

export const MainExerciseModel = (constants) => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        description: {
            type: 'input',
            validationRules: '',
            value: '',
        },

        body_parts: {
            ...buildSelector(constToSelectOptions(constants, 'body_part_content_service'), 'multiSelect'),
            value: [],
            validationRules: ''
        },
        media_type: buildSelector(constToSelectOptions(constants, 'media_type'), 'select'),
        mets: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },

        exercise_type: buildSelector(constToSelectOptions(constants, 'exercise_type'), 'select'),
        model_gender: buildSelector(constToSelectOptions(constants, 'model_gender'), 'select'),

        position: buildSelector(constToSelectOptions(constants, 'yoga_position'), 'select'),
        can_be_looped: buildSelector(constToSelectOptions(constants, 'admin_bool'), 'select'),

        wb_block_position: {
            ...buildSelector(
                constToSelectOptions(constants, 'wb_block_position'), 'select',),
            value: null,
            validationRules: ''
        },

        asymmetry_exercise: {
            inputType: 'number',
            validationRules: '',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },


        exercise_levels: buildSelector(constToSelectOptions(constants, 'fitness_level'), 'multiSelect'),
        focuses: buildSelector(constToSelectOptions(constants, 'yoga_focus'), 'multiSelect'),
        wb_sections: buildSelector(constToSelectOptions(constants, 'yoga_workout_block'), 'multiSelect'),

        age_group: buildSelector(constToSelectOptions(constants, 'age_group'), 'multiSelect'),
        bmi_group: buildSelector(constToSelectOptions(constants, 'bmi_group'), 'multiSelect'),
        yoga_type: buildSelector(constToSelectOptions(constants, 'yoga_type'), 'select'),
        contents: {
            type: 'table_collection',
            value: [],
            prototype: ContentExerciseModel(constants),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',

        }

    };
}

export const MainExerciseFilter = (constants) => {
    let model = {};

    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        name: {
            type: 'input',
            value: '',
        },
        ...model,

    };
};


export const ConnectExerciseModel = (constants, loadOptions) => {
    let objCache = {}
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        description: {
            type: 'input',
            validationRules: '',
            value: '',
        },

        media_type: buildSelector(constToSelectOptions(constants, 'media_type'), 'select'),
        mets: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },

        exercise_type: buildSelector(constToSelectOptions(constants, 'exercise_type'), 'select'),
        model_gender: buildSelector(constToSelectOptions(constants, 'model_gender'), 'select'),

        connect_id: {
            type: 'select',
            validationRules: 'required',
            onChangeEvent: 'handleSelect',
            value: '',
            requestNormalizer: (option) => {
                return option.id
            },
            responseNormalizer: (option) => {
                let exercise_id = option
                if (objCache[exercise_id] !== undefined) {
                    return objCache[exercise_id]
                }
                return API.get('admin', `/admin/wb/main/exercises/${exercise_id}`)
                    .then(data => {
                        objCache[exercise_id] = data;
                        return data;
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                getOptionLabel: option => {
                    return `[${option['id']}] ${option['name']}`
                },
                getOptionValue: option => {
                    return option['id']
                },
                pageSize: 10,
                components: {Option},
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadOptions,
            },
        },


        wb_transition_pose: buildSelector(constToSelectOptions(constants, 'yoga_transition_pose'), 'select'),
        connection_type: buildSelector(constToSelectOptions(constants, 'yoga_connection_type'), 'select'),
        contents: {
            type: 'table_collection',
            value: [],
            prototype: ContentExerciseModel(constants),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',

        }

    };
}


export const ConnectExerciseFilter = (constants) => {
    let model = {};

    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },

        connection_exercise:
        {
            type: 'input',
            value: '',
            placeholder: 'Connection_exercise_id',
            requestNormalizer: (data) => {
                return parseInt(data);
            }
        },

        name: {
            type: 'input',
            value: '',
        },
        ...model,

    };
};

export const SpecialExerciseModel = (constants) => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },

        description: {
            type: 'input',
            validationRules: '',
            value: '',
        },

        body_parts: {
            ...buildSelector(constToSelectOptions(constants, 'body_part_content_service'), 'multiSelect'),
            value: [],
            validationRules: ''
        },
        media_type: buildSelector(constToSelectOptions(constants, 'media_type'), 'select'),
        mets: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
        exercise_type: buildSelector(constToSelectOptions(constants, 'exercise_type'), 'select'),
        model_gender: buildSelector(constToSelectOptions(constants, 'model_gender'), 'select'),
        connect_block_in_position: buildSelector(constToSelectOptions(constants, 'yoga_position'), 'select'),
        connect_block_out_position: buildSelector(constToSelectOptions(constants, 'yoga_position'), 'select'),
        exercise_levels: {
            ...buildSelector(
                constToSelectOptions(constants, 'fitness_level'), 'multiSelect'
            ),
            validationRules: ''
        },
        equipment: {
            ...buildSelector(constToSelectOptions(constants, 'equipment'), 'multiSelect'),
            requestNormalizer: (data) => {
                const equipment = constants['equipment'].items
                const res = []
                if (!data || !Array.isArray(data)) {
                    return res;
                }
                for (const eq of data) {
                    res.push(equipment[eq.value].value)
                }
                return res;
            },
            validationRules: ''
        },
        yoga_type: buildSelector(constToSelectOptions(constants, 'yoga_type'), 'select'),

        contents: {
            type: 'table_collection',
            value: [],
            prototype: ContentExerciseModel(constants),
            onChangeEvent: 'handleCollection',
            validationRules: 'required',

        }

    };
}

export const ContentExerciseModel = (constants) =>{
    return {
        id: {
            inputType: 'number',
            value: null,
            isDisabled: true,
        },
        preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        media_url: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        pace: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
        audio_instruction:{
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
            requestNormalizer: (data) => {
                return data === null ? '' : data;
            }
        },
        instructor_id:{
            inputType: 'number',
            value: null,
            requestNormalizer: (data) => {
                return data === null ? null : parseInt(data);
            }
        }
    }
}


export const SpecialExerciseFilter = (constants) => {
    let model = {};

    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        name: {
            type: 'input',
            value: '',
        },
        ...model,

    };
};
