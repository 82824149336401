import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {WorkoutFilter} from "../../models/Workout";

export default class Workout extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/workouts/add';
        this.editPath = '/admin/workouts/edit';
    }

    render() {
        let constants = this.context.constants;
        return (
            <List
                name="Workouts"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/workouts"
                listApiUrl='/admin/workouts'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Internal Name', row: 'internal_name'},
                    {col: 'Duration', row: 'duration'},
                    {col: 'Workout Type', row: 'workout_type'},
                    {col: 'Day number', row: 'day_number'},


                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={WorkoutFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
